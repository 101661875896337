import styled from "styled-components";

import logo2 from "./img/logo2.svg";
import logo3 from "./img/logo3.jpg";
import logo4 from "./img/logo4.jpg";
import logo5 from "./img/logo5.jpg";
import logo6 from "./img/logo6.jpg";
import logo7 from "./img/logo7.jpg";
import logo8 from "./img/logo8.jpg";
import logoINAEM from "./img/inaem.png";
import logoResiliencia from "./img/resiliencia.jpg";
import logoUe from "./img/ue.jpg";
import { mobileWidth } from "../../../style";

function App() {
    return (
        <>
            <center><p>Proyecto financiado por el INAEM, Ministerio de Cultura y Deporte</p></center>
            <center><p>Proyecto Subvencionado por la Consejería de Cultura, Turismo y Deporte de la Comunidad de Madrid, con cargo al Plan de Recuperación, Transformación y Resiliencia</p></center>
            <center><p>Financiado por la Unión Europea - Next Generation Europea</p></center>
            
            <GovStyled>
                <FirstLogoRowStyled>
                    <LogoFirstStyled src={logoUe} />
                    <LogoFirstStyled src={logoINAEM} />
                    <LogoFirstStyled src={logoResiliencia} />
                </FirstLogoRowStyled>
                <FirstLogoRowStyled>
                    <LogoThirdStyled src={logo6} />
                    <LogoThirdStyled src={logo7} />
                    <LogoThirdStyled src={logo8} />
                </FirstLogoRowStyled>
                <FirstLogoRowStyled style={{marginTop: "1rem"}}> 
                    <LogoSecondStyled src={logo2} />
                    <LogoSecondStyled src={logo3} />
                    <LogoSecondStyled src={logo4} />
                    <LogoSecondStyled src={logo5} style={{width: "40%"}}/>
                </FirstLogoRowStyled>
            </GovStyled>
        </>
    )
}

export default App;

const GovStyled = styled.div`
overflow: hidden;
    transform: translateX(+20%);
    max-width: 70%;
    @media (max-width: ${mobileWidth}) {
    transform: translateX(+4%);
    max-width: 95%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
`

const FirstLogoRowStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 80%;
`

const LogoFirstStyled = styled.img`
    margin:0.5rem;
    max-height: 10rem;
    width: 42%;
    height: auto;
    object-fit: contain;
    margin-inline: 0.2vw;
`

const LogoSecondStyled = styled.img`
    margin:0.5rem;
    max-height: 10rem;
    width: 15%;
    height: auto;
    object-fit: contain;
    margin-inline: 0.6rem;
`;

const LogoThirdStyled = styled.img`
    margin:0.5rem;
    max-height: 10rem;
    width: 30%;
    height: auto;
    object-fit: contain;
    margin-inline: 0.8rem;
`;

